"use client";

import { CookieConsent as DefaultCookieConsent } from "react-cookie-consent";
import useIsInteractive from "@/hooks/useIsInteractive";
import { useState } from "react";

const cookieKey = "CookieConsent";

export default function CookieConsent({ data = {} }) {
  const [isInteractive, setIsInteractive] = useState(false);
  const { body, buttonLabel } = data;

  useIsInteractive(() => {
    setIsInteractive(true);
  });

  if (!isInteractive) return <></>;

  return (
    <DefaultCookieConsent
      location="bottom"
      buttonText={buttonLabel}
      cookieName={cookieKey}
      containerClasses="!bg-white !text-black border-t"
      buttonClasses="btn !bg-primary !text-white !rounded !pointer-events-auto"
      expires={150}
    >
      {body}
    </DefaultCookieConsent>
  );
}
