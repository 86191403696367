"use client";

import {useEffect, useState} from "react";
import useIsInteractive from "@/hooks/useIsInteractive";

export default function HomeVideo({ src }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) return <></>;

  return (
    <video
      autoPlay
      playsInline
      muted
      loop
      className="absolute left-0 top-0 w-full h-full object-cover z-0"
    >
      <source src={src} type="video/mp4" />
    </video>
  );
}
